<template>
    <div id="reference-letter" class="reference-letter">
        <div class="page-wrapper" id="page-preview" :style="`--pages: ${pages}; --style-color: ${styleColor}`">
            <div class="header">
                <h4>Referentie</h4>
                <q-label-switch v-if="!viewOnly" @input="handleViewModeUpdated" :value="viewMode" :options="switchOptions" class="label-switch"></q-label-switch>
                <q-button v-else variation="ghost" @click="navigateToStep(2)">Bewerken</q-button>
            </div>

            <div v-if="referenceLetter.exportAsLetter" class="letter">
                <div v-if="selectedStyle && selectedStyle.logo !== 'empty'" class="letter-row logo-wrapper">
                    <div v-if="selectedStyle.logo" class="logo-container">
                        <img :src="selectedStyle.logo" alt="" class="logo">
                    </div>
                    <span v-else-if="viewMode !== 'preview'">Logo afzender</span>
                </div>
                <div v-if="selectedStyle && selectedStyle.address && selectedStyle.address.name !== 'empty'" class="letter-row address">
                    <q-tooltip position="top">
                        <template #tooltip>
                            Wijzig het adres van de afzender onder de stijlen
                        </template>
                        <div v-if="selectedStyle && selectedStyle.address" class="lines">
                            <span class="filled">{{ selectedStyle.address.name }}</span>
                            <span class="filled">{{ selectedStyle.address.line1 }}</span>
                            <span class="filled">{{ selectedStyle.address.line2 }}</span>
                        </div>
                        <span v-else-if="viewMode !== 'preview'">Adres afzender</span>
                    </q-tooltip>
                </div>
                <div class="letter-row address">
                    <q-tooltip position="top">
                        <template #tooltip>
                            Wijzig het adres van de ontvanger in de brief instellingen
                        </template>
                        <div v-if="referenceLetter && referenceLetter.addressReceiver" class="lines">
                            <span class="filled">{{ referenceLetter.addressReceiver.name }}</span>
                            <span class="filled">{{ referenceLetter.addressReceiver.line1 }}</span>
                            <span class="filled">{{ referenceLetter.addressReceiver.line2 }}</span>
                        </div>
                        <span v-else-if="viewMode !== 'preview'">Adres ontvanger</span>
                    </q-tooltip>
                </div>
                <div class="letter-row text spaced-group">
                    <q-token-text
                        :value="referenceLetter.introText"
                        :tokens="tokens"
                        :showValues="viewMode === 'preview' || viewOnly"
                        :disabled="viewMode === 'preview' || viewOnly"
                        :placeholder="viewMode !== 'preview' ? 'Tekst brief' : ''"
                        showMissingValues
                        @isMissingValues="handleMissingValuesUpdated"
                        @input="handleIntroTextUpdated"
                    ></q-token-text>
                </div>
                <div class="letter-row footer spaced-group">
                    <q-token-text
                        :value="referenceLetter.closingText"
                        :tokens="tokens"
                        :showValues="viewMode === 'preview' || viewOnly"
                        :disabled="viewMode === 'preview' || viewOnly"
                        :placeholder="viewMode !== 'preview' ? 'Afsluiting' : ''"
                        showMissingValues
                        @isMissingValues="handleMissingValuesUpdated"
                        @input="handleClosingTextUpdated"
                    ></q-token-text>
                </div>
            </div>

            <div class="draggable-content" :class="{ 'padding-bottom': viewMode === 'edit' }">
                <draggable 
                    :list="projectIndicatorGroups" 
                    :animation="300" 
                    ghost-class="draggedIndicatorGroup" 
                    handle=".group-hamburger"
                    group="groupedIndicators" 
                    class="indicator-groups"
                    :class="{ nodrag: viewMode == 'preview' || viewOnly }"
                    @end="handleOrderChanged">
                    <div v-for="(indicatorGroup, index) in projectIndicatorGroups" :key="indicatorGroup.id" class="indicator-group spaced-group">
                        <div class="group-header">
                            <div class="left">
                                <q-icon type="hamburger-menu" class="hamburger-icon group-hamburger" color="#ADB5BD"></q-icon>
                                <h4>{{ indicatorGroup.label }}</h4>
                            </div>
                            <!-- <q-options class="right" :options="indicatorGroupOptions" v-if="viewMode !== 'preview' && activeStep !== 1" color="#C4C4C4"></q-options> -->
                        </div>
                        <draggable 
                            :list="indicatorGroup.indicators" 
                            :animation="200" 
                            ghost-class="draggedIndicatorGroup" 
                            handle=".row-hamburger" 
                            :group="`indicators-group-${index+1}`" 
                            class="indicators"
                            @end="handleOrderChanged"
                        >
                            <div v-for="indicator in indicatorGroup.indicators" :key="indicator.componentId" class="indicator-wrapper spaced-item">
                                <div v-if="indicator.isGroup" class="group">
                                    <div class="group-name">
                                        <q-icon type="hamburger-menu" class="hamburger-icon row-hamburger" color="#ADB5BD"></q-icon>
                                        <q-icon type="folder"></q-icon>
                                        <p>{{ indicator.groupName }}</p>
                                    </div>
                                    <div class="group-indicators">
                                        <div v-for="groupIndicator in indicator.indicators" :key="groupIndicator.id" class="indicator">
                                            <div class="left">
                                                <p>{{ groupIndicator.label }}</p>
                                            </div>
                                            <div class="right">
                                                <p>{{ groupIndicator.value }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="indicator">
                                    <q-icon type="hamburger-menu" class="hamburger-icon row-hamburger" color="#ADB5BD"></q-icon>
                                    <div class="left">
                                        <p>{{ indicator.label }}</p>
                                    </div>
                                    <div class="right">
                                        <p>{{ indicator.value }}</p>
                                    </div>
                                </div>
                                <div v-if="indicator.description" class="description">
                                    <p style="font-weight:500;">Toelichting</p>
                                    <p>“{{ indicator.description }}”</p>
                                </div>
                            </div>
                        </draggable>
                    </div>
                </draggable>
            </div>

            <div v-for="(page, index) in pages" :key="index" class="pagination" :style="`--index: ${index};`">
                <div class="footer">
                    <span>Pagina {{ index+1 }}/{{ pages }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';

import { isValidAnswer, getDate, getMoney, extractError } from '@/assets/js/utils.js';

import { REFERENCE_LETTER_ORDER_CHANGED } from '@/graphql/mutations';
import { GET_TOKENS } from '@/graphql/queries';

export default {
    name: 'reference-letter',
    props: {
        referenceLetter: {
            type: Object,
            required: true
        },
        project: {
            type: Object,
            required: true
        },
        configurations: {
            type: Array,
            required: true
        },
        viewOnly: {
            type: Boolean,
            default: false
        },
        viewMode: {
            type: String,
            required: true
        },
        pages: {
            type: Number,
            default: 0
        }
    },
    components :{
        draggable
    },
    data() {
        return {
            switchOptions: [
                {
                    label: 'Bewerken',
                    value: 'edit'
                },
                {
                    label: 'Voorbeeld',
                    value: 'preview'
                }
            ],
            projectIndicatorGroups: [],
            indicatorGroupOptions: [
                {
                    name: 'Bewerk kenmerk',
                    color: '#373836'
                },
                {
                    name: 'Kenmerk toevoegen',
                    color: '#373836'
                },
                {
                    name: 'Verwijder prestatie',
                    color: '#F03E3E'
                }
            ],
            letterIntroTextTemplates: [],
            letterClosingTextTemplates: [],
            letterStyleOptions: [],
            receiverAddresses: [],
            letterReceiverAddressOptions: [],
            letterIntroTextTemplateId: '',
            letterClosingTextTemplateId: '',
            tokens: []
        }
    },
    methods: {
        navigateToStep(index) {
            this.$emit('navigateToStep', index)
        },
        handleOrderChanged() {
            const order = this.projectIndicatorGroups.map(indicatorGroup => {
                return {
                    contextId: indicatorGroup.id,
                    componentIds: indicatorGroup.indicators.map(indicator => indicator.componentId)
                }
            })
            this.$apollo.mutate({
                mutation: REFERENCE_LETTER_ORDER_CHANGED,
                variables: {
                    id: this.referenceLetter.id,
                    order
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                this.referenceLetter.order = order;
                this.setComponentContexts();
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'error', message: 'Er is iets fout gegaan tijdens het opslaan van de volgorde' })
                this.reload()
            })
        },
        handleMissingValuesUpdated(isMissingValues) {
            this.$emit('isMissingValues', isMissingValues);
        },
        handleIntroTextUpdated(text) {
            this.$emit('introTextUpdated', text);
        },
        handleClosingTextUpdated(text) {
            this.$emit('closingTextUpdated', text);
        },
        async downloadReferenceletter() {

            let footer = `<div style="font-size:10px; margin-left:74px; opacity: 0.7;">${this.project.name}</div>`
            let header = `<div></div>`

            let html = await getReferenceLetterHtml()

            this.downloadLoading = true;

            const headers = {
                responseType: 'blob',
                headers: {
                    'application-context': 'qfact',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                }
            };

            Axios
                .post(`${this.downloadExportUrl}/export/html`, { html, header, footer }, headers)
                .then((response) => {
                    const content = response.headers['content-type'];
                    const exportName = getReferenceletterExportName(this.project.name);
                    download(response.data, exportName, content);
                    this.downloadLoading = false;
                })
                .catch((error) => {
                    this.$store.commit('notify', extractError(error));
                    this.downloadLoading = false;
                });
                    
            return
        },
        setTokens() {
            this.$apollo.query({
                query: GET_TOKENS,
                variables: {
                    projectId: this.project.id
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                this.tokens = result.data.tokensWithValue;
            })
            .catch(error => {
                console.log(error)
            })
        },
        setComponentContexts() {
            let componentsWithValue = this.project.references.components;

            componentsWithValue = componentsWithValue.map((component, index) => {
                let value = component.indicator.answer;
                const { answerType, displayType, unit } = component.indicator;

                if(!isValidAnswer(value) || (answerType === 'date' && !value)) value = 'Niet ingevuld'
                else if(component) {

                    if(answerType === 'organisation') {
                        const answerValues = component.indicator.answerValues || [];
                        const answer = answerValues.find(answerValue => answerValue.value === value);
                        value = answer.label;
                    }
                    else if(answerType === 'date') {
                        value = getDate(value)
                    }
                    else if(answerType === 'number') {
                        if(displayType === 'currency') value = getMoney(value)
                        else if(displayType === 'numberUnit') value+= ` ${unit}`;
                    }
                    else if(['dropdown', 'radio'].includes(answerType)) {
                        const answeredValue = component.indicator.answerValues.find(answerValue => answerValue.value === value)
                        value = answeredValue?.label
                    }
                    else if(['dropdown-multiple', 'checkbox'].includes(answerType)) {
                        if(typeof value === 'boolean') value = value ? 'Ja' : 'Nee';
                        else value = value.map(value => {
                            const answeredValue = component.indicator.answerValues.find(answerValue => answerValue.value === value)
                            return answeredValue?.label
                        }).join(', ')
                    }
                }

                const translatedLabel = this.getTranslatedLabel(component.indicator.name)
                return {
                    ...component,
                    indicatorId: component.indicator.id,
                    label: translatedLabel,
                    tokenLabel: translatedLabel.toLowerCase().replace(' ','_'),
                    value
                }
            })

            const relevantComponents = componentsWithValue.filter(component => !component.hidden);
            const answeredComponents = relevantComponents.filter(component => component.value !== 'Niet ingevuld');

            let componentsToShow = [];
            let contexts = [];

            const answeredComponentsOnly = this.referenceLetter.showAllProjectReferences || false;
            const components = relevantComponents;

            if(answeredComponentsOnly) {
                const answeredComponentIds = answeredComponents.map(component => component.id)
                componentsToShow = components.filter(component => answeredComponentIds.includes(component.id))
            } else {
                componentsToShow = components
            }

            this.totalComponents = components.length;
            this.showingComponents = componentsToShow.length;

            let groups = this.project.references.groups || [];
            const letterOrder = this.referenceLetter.order || [];

            componentsToShow.forEach(filteredComponent => {
                const componentContext = filteredComponent.indicator.context;
                let indexOfContext = contexts.findIndex(context => context.id === componentContext.id);
                if(indexOfContext === -1) {
                    const contextName = ['Project_details','Crow_project_details'].includes(componentContext.name) ? this.$t(`contexts.${componentContext.name.toLowerCase()}`) : componentContext.name;
                    const letterOrderIndex = letterOrder.findIndex(order => order.contextId === componentContext.id);
                    const order = letterOrderIndex !== -1 ? letterOrderIndex : componentContext.order;
                    contexts.push({ id: componentContext.id, label: contextName, indicators: [], order })
                    indexOfContext = contexts.length-1;
                }

                const isInGroup = groups.find(group => group.componentIds.includes(filteredComponent.id));
                if(isInGroup) {
                    const groupExists = contexts[indexOfContext].indicators.find(indicator => indicator.isGroup && indicator.groupId === isInGroup.id)
                    if(groupExists) return

                    const groupComponents = componentsToShow.filter(component => isInGroup.componentIds.includes(component.id))
                    const newGroup = {
                        componentId: groupComponents[0].id,
                        groupId: isInGroup.id,
                        groupName: isInGroup.name,
                        isGroup: true,
                        labelId: isInGroup.labelId,
                        indicators: groupComponents.map(component => { return this.parseComponentToIndicator(component) })
                    }
                    contexts[indexOfContext].indicators.push(newGroup)
                    return
                }

                contexts[indexOfContext].indicators.push(this.parseComponentToIndicator(filteredComponent))
            })

            contexts = [
                ...contexts.filter(context => context.id === "default-context"),
                ...contexts.filter(context => context.id === "crow-project-details"),
                ...contexts.filter(context => !['default-context','crow-project-details'].includes(context.id)),
            ]
            
            contexts.sort((a, b) => {
                if(a.order > b.order) return 1
                if(b.order > a.order) return -1
                return 0
            })
            contexts.forEach(context => {
                context.indicators.sort((a, b) => {
                    const orderContext = letterOrder.find(orderContext => orderContext.contextId === context.id);
                    if(!orderContext) return 1
                    const indexOfContextA = orderContext.componentIds.findIndex(componentId => componentId == a.componentId);
                    const indexOfContextB = orderContext.componentIds.findIndex(componentId => componentId == b.componentId);

                    if(indexOfContextA == -1) return 0
                    if(indexOfContextA > indexOfContextB) return 1
                    else return -1
                })
            })
            this.projectIndicatorGroups = contexts;
        },
        getTranslatedLabel(indicatorName) {
            let label = this.$t(`indicators.${indicatorName.toLowerCase()}`)
            if(label.includes('indicators.')) return indicatorName
            return label
        },
        parseComponentToIndicator(component) {
            return {
                id: component.indicator.id,
                componentId: component.id,
                indicatorId: component.indicator.id,
                label: component.label,
                tokenLabel: component.tokenLabel,
                answerValues: component.indicator.answerValues,
                answerType: component.indicator.answerType,
                value: component.value,
                description: ''
            }
        },
        handleViewModeUpdated(viewMode) {
            this.$emit('viewModeUpdated', viewMode)
        }
    },
    computed: {
        styleColor: function() {
            if(!this.selectedStyle) return '#00A1AE'
            return this.selectedStyle.color.colorCode
        },
        selectedStyle: function() {
            const styleId = this.referenceLetter?.styleId;
            const selectedStyle = this.styleTemplates.find(style => style.id === styleId);
            return selectedStyle
        },
        styleTemplates() {
            return this.configurations.filter(configuration => configuration.type === 'style');
        }
    },
    created() {
        this.setComponentContexts();
        this.setTokens();
    },
    watch: {
        project: {
            handler() {
                this.setComponentContexts();
                this.setTokens();
            },
            deep: true
        },
        referenceLetter: {
            handler() {
                this.setComponentContexts();
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.pagination {
    position: absolute;
    height: var(--height);
    inset-inline: 0;
    top: calc(var(--height) * var(--index));
    left: 0;
    pointer-events: none;

    .footer {
        position: absolute;
        bottom: 10px;
        left: 0;
        inset-inline: 44px;
        border-bottom: 1px solid #DEE2E6;
        display: flex;
        justify-content: flex-end;

        span {
            margin-bottom: 12px;
            margin-right: 12px;
            font-size: 14px;
            color: #808080;
        }
    }
}

.reference-letter {
    --font-size: 14px;
    --height: 1122.519685px;

    padding-left: 25vw;
    min-width: calc(100% - 25vw) !important;
    max-width: calc(100% - 25vw) !important;

    .page-wrapper {
        position: relative;
        padding: 44px;
        padding-bottom: 0;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .label-switch {
                margin-block: 2px 3px;
                width: 300px;
            }
        }

        .letter {
            display: flex;
            flex-direction: column;
            gap: 50px;
            margin-top: 20px;
            font-family: 'Gotham';

            .letter-row {
                display: flex;
                user-select: none;

                .filled {
                    font-style: normal;
                    line-height: 20px;
                    white-space: pre-wrap;
                }

                span {
                    font-size: var(--font-size);
                    font-style: italic;
                }
            }

            .logo-wrapper {
                justify-content: flex-end;

                & span {
                    padding-block: 20px;
                }

                .logo-container {
                    display: grid;
                    place-items: center;
                    min-height: 5vh;
                    min-width: 8vh;
                    border-radius: 6px;
                    overflow: hidden;

                    .logo {
                        max-width: 25vh;
                        max-height: 10vh;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .address {
                .lines {
                    display: flex;
                    flex-direction: column;
                }
            }

            .text {
                position: relative;
                width: 100%;
            }

            .footer {
                span {
                    min-height: 140px;
                }
            }
        }

        .draggable-content {
            &.padding-bottom {
                padding-bottom: 60px;
            }

            .indicator-groups {
                margin-top: 32px;
                font-family: 'Gotham';

                &.nodrag {
                    .hamburger-icon {
                        margin-left: -24px !important;
                    }
                    .indicators .indicator-wrapper .group .group-indicators .indicator .right {
                        margin-left: -14px;
                    }
                    .description {
                        margin-left: 0 !important;
                    }
                }

                .hamburger-icon, .description {
                    transition: .2s ease;
                }

                .indicator-group {
                    padding-block: 14px 8px;
                    margin-block: 20px;
                    border-radius: 6px;
                    transition: background-color .2s ease, translate .1s ease-in-out;

                    .group-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        .left {
                            display: flex;
                            align-items: center;
                            gap: 6px;
                            overflow: hidden;

                            h4 {
                                color: #212529;
                                padding-bottom: 3px;
                                border-bottom: 1px solid var(--style-color);
                                border-radius: 1px;
                            }
                        }
                    }

                    .indicators {
                        margin-top: 12px;

                        .indicator-wrapper {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            border-radius: 4px;
                            transition: background-color .2s ease, translate .1s ease-in-out;
                            overflow: hidden;

                            .group {
                                margin-block: 6px;
                                transition: .2s ease;

                                .group-name {
                                    display: flex;
                                    align-items: center;
                                    gap: 6px;
                                    p {
                                        font-weight: 500;
                                    }
                                }

                                .group-indicators {
                                    display: flex;
                                    flex-direction: column;
                                    margin-top: 6px;
                                    margin-left: 24px;

                                    .indicator {
                                    }
                                }
                            }

                            .indicator {
                                display: flex;
                                align-items: center;
                                gap: 6px;
                                margin-block: 6px;
        
                                .left {
                                    width: 60%
                                } .right {
                                    transition: .2s ease;
                                    width: 40%;
                                }
        
                                .left {
                                    p {
                                        font-weight: 500;
                                    }
                                }
                            }

                            .description {
                                margin-left: 24px;
                            }
                        }

                    }
                }

            }

            .draggedIndicatorGroup {
                background-color: #e9e9e950;
                cursor: grabbing;
                translate: -20px 0;
            }

        }
    }
}

.hamburger-icon {
    scale: 0.8;
    cursor: grab;

    &:active, &:focus {
        cursor: grabbing;
    }
}

p {
    color: #212529 !important;
}

.indicator-list-move, .indicator-list-enter-active, .indicator-list-leave-active {
    transition: .3s ease !important;
    -webkit-transition: .3s ease !important;
}
.indicator-list-enter, .indicator-list-leave-to{
    opacity: 0;
    translate: 0 -100%;
}
.indicator-list-leave-active {
    position: absolute !important;
    width: calc(100% - 88px);
}

</style>