<template>
    <div class="active-org-wrapper" :class="{ 'expand': !loading }">
        <div class="loader-row" :class="{ show: loading }">
            <div class="loader"></div>
        </div>
        <q-section
            v-for="(org, key) in sortedOrganisations"
            :key="key"
            :hPadding="xPadding"
            vPadding="8"
            borderBottom
            class="organisation-item"
            :class="{ qlubb: org.qlubb }"
            @mouseenter.native="orgHover(org)"
            @mouseleave.native="orgStopHover(org.id)">
            <q-organization-info
                :organization="org"
                :image="org.logo"
                :address="orgRole(org)"
                :label="labelText(org)"
                @click="handleOrganisationClick(org)"
                :loadingOrganisation="clickedOrganisation"
                :id="'organisation-select-' + key"
                :buttonText="
                    hoveringQlubbId == org.id && org.qlubb ? 'show' : '' ||
                    hoveredOrg === org.id &&
                    org.status === 'active' &&
                    orgRequested(org, false)
                        ? 'Dashboard'
                        : null
                "
                :active="org.id === loggedInOrganisation"
                :icon="
                    org.status === 'new' || orgRequested(org, true) ? 'lockClosed' : 'chevronRight'
                "
                :disabled="org.status === 'new' || orgRequested(org, true)"
            >
            </q-organization-info>
        </q-section>
    </div>
</template>

<script>
import { ACCESS } from '@/graphql/mutations';
import { initializeAccessOrganisation, getAddress, extractError } from '@/assets/js/utils';
import { handleLoginSuccess } from '../assets/js/utils';

export default {
    name:'userOrganisations',
    props: {
        organisations: {
            type: Array,
            default() { return [] }
        }, 
        xPadding: {
            type: String,
            default: null
        }, 
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hoveredOrg: null,
            hoveringQlubbId: '',
            loggedInOrganisation: this.$store.getters.getJwtPayload.data.authMeta.organisationId,
            clickedOrganisation: false
        }
    },
    computed: {
        sortedOrganisations() {
            const nonActiveOrgs = this.computedOrganisations.filter(org => org && org.id !== this.loggedInOrganisation);
            const activeOrgs = this.computedOrganisations.filter(org => org && org.id === this.loggedInOrganisation);
            const organisationList = [...activeOrgs,...nonActiveOrgs];
            this.computedOrganisations.map((organisation, index) => {
                const products = organisation.products || []
                if(products.map(product => product.slug).includes('qlubb')) {
                    organisationList.splice(index+1, 0, { ...organisation, qlubb: true })
                    organisationList.join()
                }
            })
            return organisationList
        },
        computedOrganisations() {
            return this.organisations;
        }
    },
    methods: {
        orgHover(organisation) {
            if(organisation.qlubb) return this.hoveringQlubbId = organisation.id;
            this.hoveredOrg = organisation.id;
        },
        orgStopHover(id) {
            if(this.hoveringQlubbId == id) this.hoveringQlubbId = null;
            if (this.hoveredOrg === id) {
                this.hoveredOrg = null;
            }
        },
        orgRequested(org, requested) {
            const user = org.users.find(user => user.userId === this.$store.getters.getUser.id)
            return requested ? user.status === 'requested' : user.status !== 'requested';
        },
        orgRole(org) {
            const user = org.users.find(user => user.userId === this.$store.getters.getUser.id)
            return user ? this.$t(user.role.name) : null;
        },
        labelText(org) {
            const labelNew =
                org.status === 'new' ? { message: 'in afwachting van goedkeuring', type: 'info' } : null;
            const labelRequested = this.orgRequested(org, true) ? { message: 'toegang aangevraagd', type: 'info' } : null;

            return labelRequested ? labelRequested : labelNew;
        },
        async accessOrganisation(organisationId) {
            const vm = this;

            return this.$apollo
                .mutate({
                    mutation: ACCESS,
                    variables: { organisationId },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    if (response.data.user_accessOrganisation.ssoRedirectUrl) {
                        // this.$router.push(response.data.user_accessOrganisation.ssoRedirectUrl).catch(err => console.log('Initializing SSO'));
                        window.location.href = `${window.location.origin}${response.data.user_accessOrganisation.ssoRedirectUrl}`
                        return {status: 'ssoRedirect'};
                    } else {
                        vm.$store.commit('setJwt', response.data.user_accessOrganisation.jwt);
                        vm.$store.commit('setRefreshToken', response.data.user_accessOrganisation.refreshToken);

                        return response.data.user_accessOrganisation;
                    }                
                })
                .catch((err) => {
                    this.clickedOrganisation = false;
                    extractError(err)
                });
        },
        handleOrganisationClick(organisation) {
            const organisationId = organisation.id
            this.clickedOrganisation = true;
            const currentOrganisationId = this.$store.getters.getJwtPayload.data.authMeta
                ? this.$store.getters.getJwtPayload.data.authMeta.organisationId
                : null;
            const PCA_URL = process.env.PCA_URL || 'http://localhost:7070';

            if (organisationId === currentOrganisationId && !organisation.qlubb) {
                return initializeAccessOrganisation(this);
            } else {
                // access organisation to get new jwt
                const vm = this;
                this.accessOrganisation(organisationId).then(authPayload => {
                    if(organisation.qlubb) return window.location.href = `${PCA_URL}/auth?jwt=${authPayload.jwt}`
                    if (authPayload.status === 'ssoRedirect') return;
                    else return handleLoginSuccess(vm, authPayload);
                });
            }
        },
        getAddress(address) {
            return getAddress(address);
        },
    }

}
</script>

<style lang="scss" scoped>
@import './qds/assets/style/_variables.scss';
@import './qds/assets/style/fonts/fonts.css';

.active-org-wrapper {
    max-height: 220px;
    overflow: auto;
    overflow-x: hidden;

    &.expand {
        animation: expand 400ms cubic-bezier(.7,.05,.31,.95) forwards;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $color-white;
        border-radius: 4px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-grey-3;
        border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $color-grey-5;
    }
}

.active-org-wrapper > div:last-child {
    border-bottom: none !important;
}

.organisation-item:hover {
    // padding-right: 33px !important;
    background-color: #f8f9fa;
    transition: all 300ms ease;

    &.qlubb {
        background-color: rgba(74,86,165, 0.1);
    }
}

@keyframes expand {
    0% {
        max-height: 60px;
    }
    100% {
        max-height: 220px;
    }
}

.loader-row {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -60px;
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transition: 400ms cubic-bezier(.7,.05,.31,.95);
    -webkit-transition: 400ms cubic-bezier(.7,.05,.31,.95);
    
    &.show {
        margin-top: 0px;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.loader {
    $loader-color: #d1d5da;
    $loader-size: 24px;
    $loader-height: 14px;
    $loader-border-size: 3px;
    $loader-gap: 20px;
    $loader-animation-duration: 700ms;
    @import '@/components/qds/assets/loaders/loaders.scss';
    @include loader02;
}

</style>