<template>
    <div v-if="!loadingReport && report" class="wrapper">
        <q-pageflow v-model="activePageIndex" :title="`Rapportage ${isEditing ? 'bewerken' : 'samenstellen'}`" :back="`/reports${isEditing ? `/${report.id}?name=${report.name}` : ''}`" paddingLeft="30px">
            <template v-slot:items>
                <q-pageitem
                    v-for="(item, index) in computedPageflowItems"
                    :value="index"
                    :key="item.label"
                    :type="{ status: item.status }"
                    class="page-item"
                    :class="{ active: index == activePageIndex }"
                    :style="`--item-width: ${90 / computedPageflowItems.length}%; ${index <= highestVisitedStepIndex ? 'cursor: pointer;' : ''}`"
                    @click.native="navigateToStep(index)"
                >
                    <span class="label">{{ item.label }}</span>
                    <q-tooltip v-if="item.label === 'Voorwaarden' && configurationsTag.value > 0" class="tooltip" position="bottom">
                        <template v-slot:tooltip >
                            <span v-html="configurationsTag.label"></span>
                        </template>
                            <q-tag variation="success" size="small">{{ configurationsTag.value }}</q-tag>
                    </q-tooltip>
                </q-pageitem>
            </template>
        </q-pageflow>
        <div id="steps-view" class="steps-view">
            <div id="steps-wrapper" class="steps-wrapper">
                <div :id="`step-${index}`" v-for="(step, index) in visitedSteps" :key="index" class="step-container">
                    <component
                        :is="`report-step-${step.component}`"
                        :index="index + 1"
                        :step="step"
                        :activeStep="activePageIndex"
                        :report="report"
                        :indicators="indicators"
                        :labels="labels"
                        @reportUpdated="handleReportUpdated"
                        @reportUpdatedWithoutSaving="reportUpdatedWithoutSaving"
                        @loadingStarted="handleStepLoading(true)"
                        @loadingEnded="handleStepLoading(false)"
                        @requirementStepValidationUpdated="handleRequirementValidationUpdated"
                    />
                </div>          
            </div>         
        </div>
        <div class="navigation-buttons">
            <q-tooltip v-if="isDisabled" class="tooltip" position="top">
                <template v-slot:tooltip >
                    <span v-html="disabledReason"></span>
                </template>
                    <q-icon type="QuestionCircle" width="24" height="24" color="red"></q-icon>
            </q-tooltip>
                <q-button
                    class="button previous-step"
                    :class="activePageIndex > 0 ? 'show' : 'hide'"
                    variation="blank"
                    @click="previousStep"
                    >Vorige stap</q-button
                >
                <q-button class="button" variation="primary" @click="nextStep" :disabled="isDisabled" :loading="stepIsLoading">{{
                    getNextStepLabel
                }}</q-button>
        </div>
    </div>
    <jump-transition v-else>
        <div class="center">
            <div class="loader"></div>
        </div>
    </jump-transition>
</template>

<script>
// import steps
import ReportStepRequirements from './flow/ReportStepRequirements.vue';
import ReportStepProjection from './flow/ReportStepProjection.vue';
import ReportStepView from './flow/ReportStepView.vue';
import ReportStepAccess from './flow/ReportStepAccess.vue';
import ReportStepFilter from './flow/ReportStepFilter.vue';
import ReportStepName from './flow/ReportStepName.vue';

import { ACCESS_REPORT, GET_REPORT_RESULTSET } from '@/graphql/queries';
import { DELETE_REPORT, REPORT_UPDATE_CONFIGURATION } from '@/graphql/mutations';
import gql from 'graphql-tag';
import { extractError } from '../../../assets/js/utils';

export default {
    name: 'report-flow',
    components: {
        ReportStepRequirements,
        ReportStepProjection,
        ReportStepView,
        ReportStepAccess,
        ReportStepFilter,
        ReportStepName,
    },
    data() {
        return {
            activePageIndex: 0,
            lastActivePageIndex: this.activePageIndex,
            highestVisitedStepIndex: 0,
            steps: [],
            allSteps: [
                {
                    name: 'Oke, aan welke <span style="color: #00A1AE;">voorwaarden</span> moeten de gegevens voldoen om in je rapportage voor te komen?',
                    about: 'Selecteer hier de <b>voorwaarden</b> waaraan de gegevens moeten voldoen om in je rapportage voor te komen. Niet alle data die je hier selecteerd hoeft weergegeven te worden in de tabel, wat je wel wilt weergeven kies je in de volgende stap. Je kunt ook doorgaan zonder voorwaarden te kiezen, in dat geval kun je alle gegevens ophalen.',
                    component: 'requirements'
                },
                {
                    name: 'En welke <span style="color: #00A1AE;">gegevens</span> hiervan wil je weergeven in de tabel?',
                    about: `Je hebt zojuist een selectie van gegevens gemaakt waaraan je rapportage moet voldoen. Kies in deze stap welke gegevens je hiervan wilt weergeven in de tabel.`,
                    component: 'projection'
                },
                {
                    name: 'Je bent er bijna! Hoe wil je je rapportage uiteindelijk <span style="color: #00A1AE;">weergeven</span>?',
                    about: 'Bekijk hieronder je rapportage en sleep de kolommen om een gewenste weergave samen te stellen.',
                    component: 'view'
                },
                {
                    name: 'Bepaal wie er <span style="color: #00A1AE;">toegang</span> heeft tot je rapportage',
                    about: 'Selecteer hier wie de rapportage mogen inzien en bewerken en bepaal de filters die zij kunnen gebruiken op de rapportage. Dit is na deze stap op ieder moment nog aan te passen.',
                    component: 'access'
                },
                {
                    name: 'Waar wil je op kunnen <span style="color: #00A1AE;">filteren</span> in je rapportage?',
                    about: 'Nadat we je rapportage klaarzetten kun je nog extra filteropties gebruiken. Selecteer hieronder welke filteropties je wilt kunnen toepassen op je rapportage. ',
                    component: 'filter'
                },
                {
                    name: 'Je rapportage staat bijna klaar, hoe wil jij je rapportage <span style="color: #00A1AE;">noemen</span>?',
                    about: 'Geef hier je rapportage een passende naam.',
                    placeholder: 'Naam van je rapportage',
                    component: 'name'
                },
            ],
            report: null,
            loadingReport: true,
            cachedReport: null,
            scrolling: false,
            isScrolling: null,
            userHasInteracted: false,
            isEditing: false,
            stepIsLoading: false,
            disabledReasons: {
                vhbReportCheck: "Vul een projectnaam en formuliertemplate in <br>om deze rapportage te maken, of kies andere voorwaarden.", 
                projectionCheck: "Vink tenminste één checkbox aan om de rapportage te kunnen bouwen.", 
                emptyReportCheck: "Vul andere filtercombinaties in om deze rapportage verder te kunnen bouwen.", 
                finishReportCheck: "Vul een rapportage naam in om de rapportage te kunnen bouwen.", 
                computedPageflowItemsCheck: "Nog niet alle waarden zijn ingevuld."
            },
            indicators: [],
            labels: [],
            requirementsStepValid: true
        };
    },
    methods: {
        scrollToActiveStep() {
            const step = document.getElementById(`step-${this.activePageIndex}`);
            if(!step) return
            step.scrollTop = 0;
            const scrollTop = step.offsetTop;
            const stepsWrapper = document.getElementById('steps-view');
            stepsWrapper.scrollTop = scrollTop;
        },
        navigateToStep(stepIndex) {
            if (stepIndex > this.highestVisitedStepIndex) return;
            this.activePageIndex = stepIndex;
            this.lastActivePageIndex = this.activePageIndex;

            this.scrollToActiveStep();
        },
        async nextStep() {
            if (this.activePageIndex == this.steps.length - 1) return this.finishReport();

            this.activePageIndex++;
            this.lastActivePageIndex = this.activePageIndex;

            if (this.activePageIndex > this.highestVisitedStepIndex)
                this.highestVisitedStepIndex = this.activePageIndex;

            this.scrollToActiveStep();
        },
        previousStep() {
            this.activePageIndex--;
            this.lastActivePageIndex = this.activePageIndex;

            this.scrollToActiveStep();
        },
        finishReport() {
            this.$router.push(`/reports/${this.report.id}`)
        },
        getReportResultset() {
            this.$apollo
                .query({
                    query: GET_REPORT_RESULTSET,
                    variables: {
                        reportId: this.report.id,
                        conditions: {
                            columnConditions: [],
                            filterMenuConditions: [],
                        },
                        offset: 0,
                        limit: 10,
                        orderBy: [],
                        options: {
                            groupOnLabel: false,
                            labelColumnId: ''
                        }
                    },
                    fetchPolicy: 'no-cache'
                })
                .then((result) => {
                    this.data = result.data.resultset;
                })
                .catch(error => {
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de resultaten' });
                })
        },
        getReport(id) {
            this.$apollo
                .query({
                    query: ACCESS_REPORT, 
                    fetchPolicy: 'no-cache', 
                    variables: {
                        id,
                    },
                })
                .then((result) => {
                    this.report = result.data.report_access.report;
                    this.cachedReport = _.cloneDeep(this.report); 

                    this.checkAccess();
                    this.loadingReport = false;
                })
                .catch((error) => {
                    this.loadingReport = false;
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de rapportage' });
                    this.$router.push('/reports')
                });
        },
        _saveReport: _.debounce(function(updatedReport) {
            this.saveReport(updatedReport);
        }, 500),
        saveReport(updatedReport) {
            this.$apollo
                .mutate({
                    mutation: REPORT_UPDATE_CONFIGURATION,
                    variables: {
                        id: updatedReport.id,
                        configuration: updatedReport.configuration
                    },
                })
                .then((result) => {
                    this.cachedReport = _.cloneDeep(this.report);
                    this.stepIsLoading = false;
                })
                .catch((error) => {
                    this.stepIsLoading = false;
                    this.report = _.cloneDeep(this.cachedReport);
                    this.$store.commit('notify', extractError(error));
                });
        },
        handleReportUpdated(updatedReport) {
            this.userHasInteracted = true;
            this.report = { ...updatedReport };
            this.stepIsLoading = true;
            this._saveReport(updatedReport);
        },
        reportUpdatedWithoutSaving(updatedReport) {
            this.report = { ...updatedReport };
        },
        handleStepLoading(loading) {
            this.stepIsLoading = loading;
        },
        checkAccess() {
            const { id: userId } = this.$store.getters.getUser;
            const message = 'U heeft niet voldoende rechten om deze raportage aan te passen.'

            if (!this.report.users) {
                this.$store.commit('notify', { message, type: 'warning' })
                this.$router.push('/reports');
                return;
            }
            
            const thisUser = this.report.users.find(user => user.user.id === userId);

            if (!thisUser) {
                this.$store.commit('notify', { message, type: 'warning' })
                this.$router.push('/reports');
                return;
            }

            const hasOnlyViewPermission = thisUser.permissions.indexOf('view') !== -1;

            if (hasOnlyViewPermission) {
                this.$store.commit('notify', { message, type: 'warning' })
                this.$router.push('/reports');
                return;
            }
            
            return !hasOnlyViewPermission;
        },
        getIndicators() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            new Promise(async (resolve, reject) => {
                let indicators = [];
                let i = 0;

                while (true) {
                    const result = await this.$apollo
                        .query({ 
                            query: gql`
                                query Indicators($where: JSON!, $first: Int!, $skip: Int!) {
                                    indicators(where: $where, first: $first, skip: $skip) {
                                        id
                                        name
                                        isMaster
                                        masterId
                                        answerType
                                        displayType
                                        unit
                                        labelIds
                                        type
                                        answerValues {
                                            value
                                            label
                                        }
                                        organisationId
                                    }
                                }
                            `,
                            variables: {
                                where: {
                                    organisationId
                                },
                                skip: i,
                                first: 500
                            }
                        })

                    indicators = [...indicators, ...result.data.indicators];

                    i += 500;
                    
                    if (result.data.indicators.length < 500) break;
                }

                const organisationValueNames = ['Branch', 'Client', 'Contractor'];
                indicators.forEach((indicator, index) => {
                    if(organisationValueNames.includes(indicator.name)) {
                        this.setIndicatorOrganisations(indicator);

                        indicators[index].answerType = 'organisation';
                        indicators[index].loading = true;
                    }
                });

                const result = await this.$apollo
                    .query({ 
                        query: gql`
                            query Labels($where: JSON!, $first: Int!, $skip: Int!) {
                                labels(where: $where, first: $first, skip: $skip) {
                                    id
                                    masterId
                                    name
                                }
                            }
                        `,
                        variables: {
                            where: {
                                organisationId
                            },
                            skip: 0,
                            first: 500
                        }
                    })
                const labels = result.data.labels;

                resolve({ indicators, labels});
            })
            .then(({ indicators, labels }) => {
                this.indicators = indicators;
                this.labels = labels;
            })
        },
        async setIndicatorOrganisations(indicator) {
            const organisationType = indicator.name;
            const organisations = await this.$apollo
                .query({
                    query: gql`
                        query Organisations($first: Int, $skip: Int, $where: JSON, $sort: String, $caseSensitive: Boolean) {
                            organisations(
                                first: $first
                                skip: $skip
                                where: $where
                                sort: [$sort]
                                caseSensitive: $caseSensitive
                            ) {
                                id
                                name
                            }
                        }
                    `,
                    variables: {
                        where: {
                            AND: [
                                organisationType !== 'Branch' ? 
                                    {
                                        products__some: {
                                            slug__contains: organisationType.toLowerCase(),
                                            enabled: true
                                        },
                                    } : {},
                                organisationType !== 'Branch' ? 
                                    {
                                        type: 'main',
                                    } : {
                                        type: 'branch',
                                    },
                            ]
                        }
                    }
                })
                .then(result => result.data.organisations);

            indicator.answerValues = organisations.map(organisation => {
                return {
                    label: organisation.name,
                    value: organisation.id
                }
            });
            indicator.loading = false;

            const index = this.indicators.findIndex(indicator_ => indicator_.id === indicator.id);
            if(index !== -1) this.indicators[index] = indicator;
        },
        handleRequirementValidationUpdated(valid) {
            this.requirementsStepValid = valid;
        },
        isVhbReport() {
            const projectsConditions = this.report.configuration.conditions.projects || [];
            const projectAnswerValues = projectsConditions[0] ? projectsConditions[0].filters.value : null;
            const hasProjectAnswer = Boolean(projectAnswerValues);
            const formTemplateValues = projectsConditions[1] ? projectsConditions[1].filters.value : null;
            const hasProjectTemplateAnswer = Boolean(formTemplateValues);
            return hasProjectAnswer && hasProjectTemplateAnswer;
        }
    },
    computed: {
        disabledReason: function() {
            if (this.activePageIndex === 0) {
                return this.disabledReasons.vhbReportCheck;
            } else if (this.activePageIndex === 1) {
                return this.disabledReasons.projectionCheck;
            } else if (this.activePageIndex === 2) {
                return this.disabledReasons.emptyReportCheck;
            } else if (this.activePageIndex === 5) {
                return this.disabledReasons.finishReportCheck;
            } else if (!this.computedPageflowItems[this.activePageIndex].filled) {
                return this.disabledReasons.computedPageflowItemsCheck;
            }
            return false;     
        },
        isDisabled: function() {
            if (this.activePageIndex === 0) return !this.requirementsStepValid
             
            else if (this.activePageIndex === 1) {
                const visibleProjections = this.report.configuration.projection.filter((projection) => {
                    return projection.visible === true
                })
                const hasOneVisibleProjection = visibleProjections.length > 0;

                return !hasOneVisibleProjection;
            } else if (this.activePageIndex === 2) {
                const hasEmptyReport = Boolean(this.report.configuration.conditions);
                return !hasEmptyReport
            } else if (this.activePageIndex === 5) {
                const hasReportName = Boolean(this.report.name !== null && this.report.name && this.report.name.length > 0);
                return !hasReportName;
            } else {
                return !this.computedPageflowItems[this.activePageIndex].filled;
            }
        },
        computedPageflowItems: function () {
            const requirementsFilled = true;
            const dataFilled = Boolean(this.report.configuration.projection.length > 0)
            const accessFilled = Boolean(this.report.roles || this.report.users)
            const nameFilled = Boolean(this.report.name)

            let items = [
                {
                    label: 'Voorwaarden',
                    filled: requirementsFilled,
                },
                {
                    label: 'Gegevens',
                    filled: dataFilled,
                },
                {
                    label: 'Weergave',
                    filled: true,
                },
                {
                    label: 'Toegang',
                    filled: accessFilled
                },
                {
                    label: 'Filters', 
                    filled: true
                },
                {
                    label: 'Naam',
                    filled: nameFilled
                }
            ];

            items.forEach((item, index) => {
                if ((!item.filled || this.highestVisitedStepIndex < index) && !this.isEditing) {
                    item.status = 'not_done';
                }
                else {
                    item.status = 'succes';
                }
            });

            const isVhbReport = this.isVhbReport();

            items = items.filter(item => {
                if (item.label === 'Gegevens' && isVhbReport) return false;
                if (item.label === 'Filters' && isVhbReport) return false;

                return true;
            })

            this.steps = this.allSteps.filter(item => {
                if (item.component === 'projection' && isVhbReport) return false;
                if (item.component === 'filter' && isVhbReport) return false;

                return true;
            })

            return items;
        },
        visitedSteps() {
            let firstIncompleteIndex;
            this.computedPageflowItems.forEach((item, index) => {
                if(item.status == 'not_done' && firstIncompleteIndex === undefined)  firstIncompleteIndex = index;
            })

            if(firstIncompleteIndex === undefined) return this.steps
            return this.steps.filter((step, index) => true || index <= firstIncompleteIndex);
        },
        getNextStepLabel() {
            const lastStepLabel = `${this.isEditing ? 'Bekijk' : 'Bouw'} rapportage`
            return this.activePageIndex == this.steps.length - 1 ? lastStepLabel : 'Volgende stap';
        },
        configurationsTag: function() {
            const conditions = this.report.configuration.conditions;
            let activeConfigurations = 0;
            let label = '';

            Object.values(conditions).forEach(configurations => {
                configurations.forEach(configuration => {
                    activeConfigurations++;
                    label += `${this.$t(configuration.name)}<br>`
                })
            })


            return {
                value: activeConfigurations,
                label
            }
        },
    },
    created() {
        const { id, state } = this.$route.params;
        if (!id) return;
        if(state == 'edit') {
            this.highestVisitedStepIndex = this.steps.length-1;
            this.isEditing = true;
        }
        this.getReport(id);
        this.getIndicators();
    },
    beforeDestroy() {
        if(!this.userHasInteracted && !this.isEditing) {
            this.$apollo.mutate({
                mutation: DELETE_REPORT,
                variables: {
                    id: this.report.id
                }
            }) 
        } else {
            localStorage.removeItem('lastCreatedReportId')
        }
    }
};
</script>

<style lang="scss" scoped>

.page-item {
    padding: 0 !important;
    width: var(--item-width);

    &.active {
        width: calc(var(--item-width) + 10%) !important;
    }
}


.wrapper {
    .steps-view {
        height: calc(100vh - 125px - 81px);
        overflow-y: hidden;
        scroll-behavior: smooth;
        overscroll-behavior: contain;

        .steps-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .step-container {
                position: relative;
                scroll-snap-align: start;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                min-height: calc(100vh - 125px - 81px - 160px);
                max-height: calc(100vh - 125px - 81px - 160px);
                min-width: 80%;
                max-width: 100%;
                overflow-y: auto;
                padding-block: 80px;

                .step {
                    margin-block: auto;
                }
            }
        }
    }
    
    .navigation-buttons {
        position: absolute;
        bottom: 24px;
        right: 120px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 24px;
        margin: 0 auto;
        width: 664px;
        user-select: none;
        pointer-events: none;
        background: none;

        .button {
            pointer-events: all;
        }

        .previous-step {
            z-index: 0;
            transition: margin-right 0.4s ease, opacity 0.4s ease;

            &.show {
                margin-right: 0;
                opacity: 1;
            }
            &.hide {
                opacity: 0;
                margin-right: -110px;
            }
        }
    }
}

.tooltip {

}

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 5px;

    &:hover {
        background: rgb(130, 130, 130);
    }
}

@media only screen and (max-width: 1000px) {
    .test {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}

.loader {
    $loader-color: #d1d5da;
    $loader-size: 100px;
    $loader-height: 14px;
    $loader-border-size: 5px;
    $loader-gap: 20px;
    $loader-animation-duration: 700ms;
    @import '@/components/qds/assets/loaders/loaders.scss';
    @include loader02;
}
</style>
